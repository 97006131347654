/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;

@include mat.core();

$my-app-primary: mat.define-palette(mat.$indigo-palette);
$my-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-light-theme((color: (primary: $my-app-primary,
                accent: $my-app-accent,
                warn: $my-app-warn,
            ),
        ));

@include mat.all-component-themes($my-app-theme);

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.card-header {
    min-height: 50px;
}

.guest {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.unread-msg {
    background: #3f51b5;
    width: 25px;
    height: 25px;
    line-height: 12px;
    border-radius: 50%;
    font-size: 14px;
    color: #fff;
    text-align: center;
    display: inline-block;
    padding: 7px;
}

.read-msg {
    background: transparent;
    width: 25px;
    height: 25px;
    line-height: 12px;
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    display: inline-block;
    padding: 7px;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
    z-index: 10000 !important;
}

.discrepancy {
    cursor: pointer;
    text-decoration: underline;
}

.text-danger {
    color: red !important;
}

.form-item {
    font-size: 13px !important; // example
    height: 47px !important;
}

.e-outline {
    margin-top: 5px;
    height: 44px;
}

.cursor-pointer {
    cursor: pointer;
}

.e-daterangepicker.e-popup .e-date-range-container {
    background-color: #fff !important;
}